import React, { Component } from 'react';
import {   Card,   CardBody,  CardImg } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getProfile, getCourseInfo, getLoggedInUser } from '../../auxiliares/funciones';
import { getToday } from '../../auxiliares/dates';

import Eventos from './eventos'
import SweetAlert from "react-bootstrap-sweetalert";


// import images
import img2 from "../../assets/images/img-cursos.jpg";
import CONFIG from '../../CONFIG.json';


class Lateral extends Component {
    constructor(props) {
        super(props);
        this.state = {
            curso:getCourseInfo(),
            muestraMsg: false,
            msg: {}
        }
    }

    muestraMensajeChat = () =>{
      if(getProfile() === 4){
        const curso = this.state.curso
        if(curso.fechasChat !== ''){
          const chat = curso.fechasChat.split(',')[0]
          this.setState({muestraMsg:true, msg:{tipo:'info',titulo:"Próximo chat",texto:'El próximo chat es '+chat.toLowerCase()}})
        }else{
          this.setState({muestraMsg:true, msg:{tipo:'info',titulo:"Sin chats",texto:'No hay chats programados pendientes'}})
        }
      }
    }

    verDiploma = () =>{
      const win = window.open('https://firma.escuelaformacion.es/descargar_DiplomaPlat.cshtml?Datos='+this.state.curso.diploma, '_blank');
      win.focus();
      const ses = getLoggedInUser()
      fetch(CONFIG.api + "Cursos?action=diploma&token=" + ses.token+"&id="+this.props.id)
    }

    render() {
      const perfil = getProfile()
      const ses = getLoggedInUser()
      const curso = this.state.curso
        return (
            <React.Fragment>
                  <Card>
                      {this.state.muestraMsg &&
                        <SweetAlert
                        type={this.state.msg.tipo}
                        title={this.state.msg.titulo}
                        onConfirm={() => this.setState({ muestraMsg: false })}
                        >
                        {this.state.msg.texto}
                        </SweetAlert>
                      }
                      <CardImg top className="img-fluid" src={img2} alt="Imagen curso"/>
                      <CardBody>
                          <h4 className="card-title">{curso.nombre}</h4>
                      </CardBody>
                      <ul className="list-group mini-stat list-group-flush bg-primary">
                          {perfil !== 3 &&
                          <li className="list-group-item mini-stat-img" style={{color:'white',cursor : "pointer"}}>
                            <Link to={'/curso/'+this.props.id+'/calificaciones'}><i className="dripicons-article"></i> Calificaciones</Link>
                          </li>
                          }
                          {perfil !== 3 &&
                          <li className="list-group-item mini-stat-img" style={{color:'white',cursor : "pointer"}}>
                            <Link to={'/curso/'+this.props.id+'/foros'}><i className="dripicons-broadcast"></i> Foros</Link>
                          </li>
                          }
                          <li className="list-group-item mini-stat-img" style={{color:'white',cursor : "pointer"}}>
                            <Link to={"/curso/"+this.props.id+"/glosario"}><i className="dripicons-question"></i> Glosario</Link>
                          </li>
                          <li className="list-group-item mini-stat-img" style={{color:'white',cursor : "pointer"}}>
                            <Link to={"/curso/"+this.props.id+"/biblioteca"}><i className="dripicons-bookmarks"></i> Biblioteca</Link>
                          </li>
                          <li className="list-group-item mini-stat-img" style={{color:'white',cursor : "pointer"}}>
                            <Link to={"/curso/"+this.props.id+"/cuestionario"}><i className="dripicons-clipboard"></i> Cuestionario sobre el curso</Link>
                          </li>
                          { (curso.diploma && curso.diploma!=='0' &&  getProfile() === 4) &&
                          <li className="list-group-item mini-stat-img" style={{color:'white',cursor : "pointer"}}>
                            <Link to={"#"} onClick={()=>{this.verDiploma()}}><i className="dripicons-graduation"></i> Descargar diploma</Link>
                          </li>
                          }
                          {perfil !== 3 &&
                           (getToday('string') === curso.fechasChatDate ?
                          <li className="list-group-item mini-stat-img" style={{color:'white',cursor : "pointer"}}>
                            <Link to={'/curso/'+this.props.id+'/chat/'+curso.chatid}><i className="dripicons-conversation"></i> Sala de chat</Link>
                          </li>
                          :<li className="list-group-item mini-stat-img" style={{color:'white',cursor : "pointer"}}
                              onClick={()=>{this.muestraMensajeChat()}}>
                            <i className="dripicons-conversation"></i> Sala de chat
                          </li>)
                          }
                          {perfil !== 3 &&
                           curso.guiaurl &&
                          <li className="list-group-item mini-stat-img" style={{color:'white',cursor : "pointer"}}>
                            <a href={curso.guiaurl+'&token='+ses.token} target="_blank" rel="noopener noreferrer"><i className="dripicons-document"></i> Guía didáctica</a>
                          </li>
                          }
                          {curso.pdfcursourl && curso.pdfcursourl !== '' &&
                          <li className="list-group-item mini-stat-img" style={{color:'white',cursor : "pointer"}}>
                            <a href={curso.pdfcursourl} target="_blank" rel="noopener noreferrer"><i className="dripicons-document-new"></i> PDF del curso</a>
                          </li>
                          }
                          {getProfile() === 4 && <li className="list-group-item mini-stat-img" style={{color:'white',cursor : "pointer"}}>
                            <Link to={'/mensajes/'+curso.tutorid}><i className="dripicons-message"></i> Contactar con el tutor</Link>
                          </li>
                          }
                          {getProfile() === 4 && <li className="list-group-item">
                            <i className="dripicons-user"></i> Tutor: {this.state.curso.tutor}
                          </li>
                          }
                          {getProfile() === 4 && <li className="list-group-item">
                            <i className="dripicons-phone"></i> Teléfono tutor: {this.state.curso.tutortelf}
                          </li>
                          }
                      </ul>
                  </Card>
                  {getProfile() === 4 && <Eventos grupoid={this.props.id} muestraMensajeChat={this.muestraMensajeChat}/>}
            </React.Fragment>
        );
    }
}

export default connect(null, { })(Lateral);
